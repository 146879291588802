import * as React from "react"
import Layout from "../components/layout"
import "../main.scss"
import { navigate } from 'gatsby-link'

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout>
      <header id="contact">Contact Us</header>
      <div id="form-container">
      <div id="formWrapper">
      <form
        name="contact"
        method="POST"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="file-upload" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <input type="text" name="name" placeholder="Name" onChange={handleChange} />
        <input type="email" name="email" placeholder="Email" onChange={handleChange} />
        <textarea name="message" placeholder="Message" onChange={handleChange}></textarea>
        <input name="file" type="file" onChange={handleAttachment}/>
        <p class="small"><em>Maximum size limit of 8 MB. Can only upload one file.</em></p>
        <input type="submit" value="Submit"></input>
      </form>
      <div class="info">
        <p><strong>Phone Number:</strong></p>
        <p>704-945-4900</p>
        <br/>
        <p><strong>Address:</strong></p>
        <div id="address">
          <p>PeacePoint</p>
          <p>PO Box 833</p>
          <p>Blowing Rock, NC 28605</p>
        </div>
        <br/>
      </div>
    </div>
    </div>
    </Layout>
  )
}
